import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { veiculoStore } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newVeiculo } from '@/interfaces/veiculo';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.entityObject = null;
        this.search = '';
        this.selectedItem = {};
    }
    get veiculos() {
        return veiculoStore.veiculos;
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            return;
        }
        if (this.id) {
            const result = await veiculoStore.updateVeiculo(this.entityObject);
        }
        else {
            const result = await veiculoStore.createVeiculo(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        await this.$router.push({ name: 'main-veiculos' });
    }
    async mounted() {
        if (this.$router.currentRoute.params.id) {
            // @ts-ignore
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await veiculoStore.getVeiculo(parseInt(this.$router.currentRoute.params.id, 0));
        }
        else {
            this.entityObject = newVeiculo();
        }
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
        },
    })
], Create);
export default Create;
