var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Veículo")])]),_c('v-card-subtitle',[(_vm.id)?_c('div',[_vm._v("Atualizar Registro")]):_c('div',[_vm._v("Criar Registro")])]),(_vm.entityObject)?_c('v-card-text',[[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Capacidade","vid":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Capacidade","type":"number","min":"1","error-messages":errors},model:{value:(_vm.entityObject.capacidade),callback:function ($$v) {_vm.$set(_vm.entityObject, "capacidade", $$v)},expression:"entityObject.capacidade"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Placa","vid":"placa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Placa","error-messages":errors},model:{value:(_vm.entityObject.placa),callback:function ($$v) {_vm.$set(_vm.entityObject, "placa", $$v)},expression:"entityObject.placa"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Nome do motorista","vid":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome do motorista","error-messages":errors},model:{value:(_vm.entityObject.motorista_nome),callback:function ($$v) {_vm.$set(_vm.entityObject, "motorista_nome", $$v)},expression:"entityObject.motorista_nome"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Telefone do motorista","vid":"idade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"label":"Telefone do motorista","type":"text"},model:{value:(_vm.entityObject.motorista_telefone),callback:function ($$v) {_vm.$set(_vm.entityObject, "motorista_telefone", $$v)},expression:"entityObject.motorista_telefone"}})]}}],null,true)})],1)],1),(_vm.entityObject)?_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Observações","rows":"3","no-resize":""},model:{value:(_vm.entityObject.observacao),callback:function ($$v) {_vm.$set(_vm.entityObject, "observacao", $$v)},expression:"entityObject.observacao"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Veículo ativo"},model:{value:(_vm.entityObject.is_active),callback:function ($$v) {_vm.$set(_vm.entityObject, "is_active", $$v)},expression:"entityObject.is_active"}}),_c('span',[_vm._v("Um veículo inativo não poderá ser utilizado em uma nova rota")])],1)],1)]],2):_vm._e(),_c('v-card-actions',[_c('save-button',{ref:"saveButton",on:{"submit":_vm.submit}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }