export function newVeiculo() {
    return {
        capacidade: 1,
        placa: '',
        motorista_nome: '',
        motorista_telefone: '',
        observacao: '',
        company_id: 0,
        is_active: true,
    };
}
